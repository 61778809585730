/* Warning: please do not statically import any dependencies here except for static assets like icons */
import { NotificationIcon } from '@panwds/icons'
import { _T } from './i18n'
// import { AuthState } from '@sparky/framework'

// const mspNotificationAvail = ({ auth }: { auth: AuthState }) =>
//   !/^\/(?:msp|asc)\b/.test(window.location.pathname) && auth?.tsg_id && !auth.tsgs?.hasSubTenants(auth.tsg_id)
// const mspNotificationAvail = () => !/^\/(?:msp|asc)\b/.test(window.location.pathname)

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ vars, sparkyFramework: fx }: any) => {
  if (!fx.getFrameworkVar('use_new_left_nav')) {
    return null
  }
  if (fx.getFrameworkVar('msp') && fx.hasMicroApp('mt-notifications-ui')) {
    return null
  }
  return {
    actions: [
      {
        key: 'announcements',
        title: _T('Announcements'),
        navComponent: () => import(/* webpackPreload: true */ './NavNotifications'),
        mapStateToProps: () => ({ vars }),
        icon: NotificationIcon,
        // avail: fx.hasMicroApp('mt-notifications-ui') ? mspNotificationAvail : () => true,
        avail: () => true,
        callAvail: 'override',
        _override_existing: true,
      },
    ],
  }
}
