// DO NOT IMPORT ANY LIBS HERE!!!

import en from './translations/en.json'
import de from './translations/de.json'
import ja from './translations/ja.json'
import fr from './translations/fr.json'

// i18next is too complicated for this simple micro-app

const translations: Record<string, Record<string, string | undefined>> = {
  en,
  de,
  ja,
  fr,
  default: en,
}

const navLang = navigator.language.split('-', 1)[0]
const translation: Record<string, string | undefined> = translations[navLang] || translations.default

export const _T = (key: string) => translation[key] || key
